import IconAngular from "./angular@2x.png"
import IconChrome from "./chrome@2x.png"
import IconCss from "./css@2x.png"
import IconGit from "./git@2x.png"
import IconGraphql from "./graphql@2x.png"
import IconHtml5 from "./html5@2x.png"
import IconHttp from "./http@2x.png"
import IconJs from "./javascript@2x.png"
import IconNodejs from "./nodejs@2x.png"
import IconReact from "./react@2x.png"
import IconSass from "./sass@2x.png"
import IconTypeScript from "./typescript@2x.png"
import IconWebpack from "./webpack@2x.png"
import IconVscode from "./vscode@2x.png"
import IconVue from "./vue@2x.png"
import IconJD from "./jd@2x.png"
import IconPdd from "./pdd@2x.png"
import IconTencent from "./tencent@2x.png"

export {
  IconAngular,
  IconChrome,
  IconCss,
  IconGit,
  IconGraphql,
  IconHtml5,
  IconHttp,
  IconJs,
  IconNodejs,
  IconReact,
  IconSass,
  IconTypeScript,
  IconWebpack,
  IconVscode,
  IconVue,
  IconJD,
  IconPdd,
  IconTencent,
}
